import loadable from '@loadable/component';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SnackbarProvider } from 'notistack';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

const Login = loadable(() => import('./pages/Login'))
const App = loadable(() => import('./App'))

/*webpackHotDevClient.js*/
fetch("/getUser")
  .then(response => {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    } else {
      return response.text();
    }
  })
  .then(us => {
    if (us && us.users && !us.users.name)
      window.location = '/login'
    return us;
  })
  .then(user => {
    root.render(
      <LocalizationProvider dateAdapter={AdapterDateFns} >
        <SnackbarProvider dense maxSnack={4}
        >
          <App moduline={user.moduline} user={user.users} timeout_session={user.timeout_session} />
        </SnackbarProvider>
      </LocalizationProvider>
    )
    // ReactDOM.render(
    //   (
    //     <SnackbarProvider dense maxSnack={4}
    //     >
    //       <App moduline={user.moduline} user={user.users} timeout_session={user.timeout_session} />
    //     </SnackbarProvider>
    //   ),
    //   document.getElementById('root'),
    // )
  })



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
